import apiClient from 'services/axios'
import store from 'store'

export async function login(email, password) {
  return apiClient
    .post('/authentication/signin', {
      email,
      password,
    })
    .then(response => {
      if (response && response.status === 200) {
        const accessToken = response.headers['x-token']
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        // return response.data
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, firstName, lastName) {
  return apiClient
    .post('/authentication/signup', {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
    })
    .then(response => {
      if (response && response.status === 201) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/me')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/authentication/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}

export async function forgot(data) {
  return apiClient
    .post('/authentication/forgot', {
      email: data.email,
    })
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function verifyToken(email, token) {
  return apiClient
    .get(`/authentication/reset?email=${email}&token=${token}`)
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function reset(token, password, confirmPassword) {
  return apiClient
    .post('/authentication/reset', {
      token,
      password,
      confirm_password: confirmPassword,
    })
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
