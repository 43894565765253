import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as companies from 'services/companies'
import actions from './actions'

export function* GET_ALL() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
      single: null,
    },
  })

  const response = yield call(companies.getAll)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET({ payload }) {
  const { id } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      single: null,
    },
  })

  const response = yield call(companies.get, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        single: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* ADD({ payload }) {
  const { values } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(companies.add, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Une entreprise a été ajoutée avec succès',
    })

    yield put({ type: actions.GET_ALL })
    history.push('/entreprises')
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(companies.update, id, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Une entreprise a été modifiée avec succès',
    })

    yield put({
      type: actions.GET,
      payload: { id },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(companies.remove, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Une entreprise a été suprimée avec succès',
    })

    yield put({ type: actions.GET_ALL })
    history.push('/entreprises')
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* APPROVE({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(companies.approve, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    history.push('/entreprises')
    notification.success({
      message: 'Félicitation',
      description: "L'entreprise a été approuvée avec succès",
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL, GET_ALL),
    takeEvery(actions.GET, GET),
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.APPROVE, APPROVE),
  ])
}
