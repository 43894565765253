import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiClient = axios.create({
  // eslint-disable-next-line no-underscore-dangle
  baseURL: window._env_.API_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers['X-Token'] = accessToken
    request.headers.Accept = 'application/json'
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
    notification.warning({
      message: JSON.stringify(data),
    })
  }
})

export default apiClient
