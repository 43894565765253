import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as users from 'services/users'
import actions from './actions'

const getRole = (state, value) => {
  return state.roles.list.find(item => item.value === value)
}

const getOccupation = (state, id) => {
  return state.occupations.list.find(item => item.id === id)
}

export function* GET_ALL({ payload }) {
  const { role } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const response = yield call(users.getAll, role)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET({ payload }) {
  const { id } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      single: null,
    },
  })

  const response = yield call(users.get, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        single: {
          ...response,
          roles: yield select(getRole, response.roles),
          occupation: yield select(getOccupation, response.occupation?.id),
        },
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* ADD({ payload }) {
  const { values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(users.add, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Un utilisateur a été ajoutée avec succès',
    })

    history.push('/utilisateurs')
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(users.update, id, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Un utilisateur a été modifiée avec succès',
    })

    history.push('/utilisateurs')
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(users.remove, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Un utilisateur a été suprimée avec succès',
    })

    yield put({
      type: actions.GET_ALL,
      payload: {
        role: null,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL, GET_ALL),
    takeEvery(actions.GET, GET),
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
  ])
}
